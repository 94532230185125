var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-10"},[_c('v-card-title',[_c('h3',{staticClass:"zamu-heading admin-title admin-section-title"},[_vm._v(" Blog Content List")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-text-field',{staticClass:"admin-search",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.notification !== '')?_c('v-alert',{staticClass:"mt-5",attrs:{"dense":"","text":"","type":("" + _vm.actionClass)}},[_vm._v(" "+_vm._s(_vm.notification)+" ")]):_vm._e(),(_vm.posts === null)?_c('div',[_c('h3',[_vm._v("No posts found")])]):_c('v-data-table',{staticClass:"elevation-1 users-table",attrs:{"headers":_vm.headers,"items":_vm.postData,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"main-item"},[_vm._v(" "+_vm._s(item.title))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"main-item"},[_vm._v(" "+_vm._s(_vm.getCategory(item.category)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status === 1 ? 'active' : 'deactivated'},[_vm._v(" "+_vm._s(item.status === 1 ? 'active' : 'deactivated'))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-right mt-14",attrs:{"flat":"","width":"100%"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-4",attrs:{"color":("" + _vm.zamuPurple2),"href":"/admin/new-blog","dark":""}},[_vm._v(" New blog ")]),_c('v-btn',{staticClass:"mb-2 mr-4",attrs:{"color":("" + _vm.zamuPurple2),"href":"/admin/blog-categories","dark":""}},[_vm._v(" Blog categories ")]),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline delete-headline",attrs:{"align":"center","justify":"center"}},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),(_vm.posts !== null)?_c('v-pagination',{staticClass:"zamu-pagination mt-5",attrs:{"length":_vm.pagination.lastPage,"total-visible":7},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }