<template>
    <v-card class="mt-10">
            <v-card-title >
               <h3 class="zamu-heading admin-title admin-section-title"> Blog Content List</h3>
            <v-spacer></v-spacer>
            
            </v-card-title>
            <v-card-text>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="admin-search"
                outlined
                dense
            ></v-text-field>
            </v-card-text>
               <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                </v-alert>
            <div v-if="posts === null">
                <h3>No posts found</h3>

            </div>
                <v-data-table
                :headers="headers"
                :items="postData"
                :search="search"
                sort-by="calories"
                class="elevation-1 users-table"
                v-else
            >
            <template v-slot:item.title="{ item }" width="20%">
              <span class="main-item"> {{ item.title }}</span>
            </template>
            <template v-slot:item.category="{ item }">
              <span class="main-item"> {{ getCategory(item.category) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span class="" :class="item.status === 1 ? 'active' : 'deactivated'"> {{ item.status === 1 ? 'active' : 'deactivated' }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat class="text-right mt-14" width="100%">
                <v-spacer></v-spacer>
                  <v-btn :color="`${zamuPurple2}`" href="/admin/new-blog" dark class="mb-2 mr-4">
                        New blog
                    </v-btn>
                    <v-btn :color="`${zamuPurple2}`" href="/admin/blog-categories" dark class="mb-2 mr-4">
                        Blog categories
                    </v-btn>
              
                <v-dialog v-model="dialogDelete" max-width="550px">
                  <v-card>
                    <v-card-title align="center" justify="center" class="headline delete-headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-eye
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
                </v-data-table>
                <v-pagination v-if="posts !== null" v-model="currentPage"  class="zamu-pagination mt-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>

     </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'Team',
     components: {
    },
    mixins: [coreMixin, colorMixin],

    data() {
        return {
            posts: null,
            search: '',
            dialog: false,
            dialogDelete: false,
            currentPage: 1,
            categories: null,
            deletedID: null,
            notification: '',
            actionClass: '',


        headers: [
          {
            text: 'Title',
            align: 'start',
            sortable: false,
            value: 'title',
          },
          { text: 'Status', value: 'status' },
          { text: 'Category', value: 'category' },

          { text: 'Date Created ', value: 'created_at' },
          { text: 'Date Updated', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editedIndex: -1,
        };
    },
     computed: {
         ...mapGetters(['getUpdateStatus']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
        postData () {
            return this.posts !== null ? this.posts.data : null;
        },
        pagination () {
            let data = null;
            if (this.posts !== null) {
                 data = {
                    total: this.posts.total,
                    perPage: this.posts.perPage,
                    page: this.posts.page,
                    lastPage: this.posts.lastPage,
                };
            }
            return data;

        },
    },
    watch: {
        async getUpdateStatus(status) {
            if(status) {
                this.posts = null;
                this.posts = await this.retrievePosts(this.currentPage);
            }
        },
         async currentPage() {
              this.posts = null;
             this.posts = await this.retrievePosts(this.currentPage);

        },
     
    },
    async mounted() {
        this.posts = null;
        this.posts = await this.retrievePosts(this.currentPage);
        const categoryData = await this.retrieveBlogCategories();
        this.categories = categoryData.data;
    },
    methods: {
    ...mapMutations(['setUpdateStatus']),

         getCategory(id) {
         let category = this.categories === null ? 'N/A' :this.categories.filter(function (e) {
            return e.id === id;
        });
          return category[0].category;

        },

        editItem (item) {
            return this.$router.push({ name: 'singleBlog', params: { id: item.id } });
         
        },

      deleteItem (item) {
        this.deletedID = item.id;
        this.dialogDelete = true
      },

        async deleteItemConfirm () {

              const payload = {
                    status: 0,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: `blog/deactivate/${this.deletedID}`,
                };
                  try {
                    const res = await this.axiosPutRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.data ? 'Blog post deactivated successful' : 'Blog post failed to deactivate';
                    this.actionClass = res.data  ? 'success' : 'error';
                    if(res.data) {
                      this.retrievePosts(this.currentPage)
                      location.reload();
                    }
                  

                } catch (error) {
                    this.notification = 'Blog post failed to deactivate. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    }

}
</script>

<style>

</style>